import timeZones from 'timezones.json';
import { getTimezoneOffset } from 'date-fns-tz';

function getTimeZones() {
  return timeZones.filter((tz) => !tz.isdst);
}

function detectTimeZoneFromBrowser() {
  if (Intl && Intl.DateTimeFormat) {
    const dateTimeFormat = Intl.DateTimeFormat();
    if (dateTimeFormat && dateTimeFormat.resolvedOptions) {
      const resolvedOptions = dateTimeFormat.resolvedOptions();
      if (resolvedOptions && resolvedOptions.timeZone) {
        const timeZone = getTimeZones().find((tz) =>
          tz.utc.includes(resolvedOptions.timeZone),
        );
        if (timeZone) {
          return timeZone.utc[0];
        }
      }
    }
  }

  return null;
}

function detectTimeZoneFromDate() {
  const date = new Date();
  const timeZone = getTimeZones().find(
    (timeZone) =>
      getTimezoneOffset(timeZone.utc[0], date) / 60000 ===
      -date.getTimezoneOffset(),
  );
  return timeZone?.utc[0] || null;
}

function isValidTimeZone(timeZone) {
  return getTimeZones().some((tz) => tz.utc[0] === timeZone);
}

function getDefaultUtcTimeZone() {
  const timeZones = getTimeZones();
  const timeZone = timeZones.find(
    (tz) => tz.value === 'UTC' || tz.abbr === 'UTC',
  );
  return timeZone.utc[0];
}

export {
  getTimeZones,
  detectTimeZoneFromBrowser,
  detectTimeZoneFromDate,
  isValidTimeZone,
  getDefaultUtcTimeZone,
};
