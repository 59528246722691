import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import config from '../src/config/config';

if (document.location.hostname.startsWith('www.') && !config.appUrl.startsWith('www.')) {
  document.location.href = config.appUrl;
} else {
  Sentry.init({
    dsn: 'https://a6422b548627e6644d5e753871272585@o218188.ingest.sentry.io/4506665939959808',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/api\.onemon\.io/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
