import { lazy, useEffect } from 'react';
import { usePersistStore } from './persistStore';
import 'antd/dist/reset.css';
import './styles/app.scss';
import { ConfigProvider, theme } from 'antd';
import themes from './enums/themes';
import primaryColor from './styles/primary-color.mjs';
import isDarkModePreferred from './helpers/isDarkModePreferred';

const Panel = lazy(() => import('./Panel'));
const Auth = lazy(() => import('./Auth'));

function isUserLoggedIn() {
  return !!usePersistStore.getState().getAuthToken();
}

function App() {
  const { token } = theme.useToken();
  useEffect(() => {
    const domRoot = document.querySelector(':root');
    domRoot.style.setProperty('--borderRadius', token.borderRadius);
    domRoot.style.setProperty('--boxShadowTertiary', token.boxShadowTertiary);
    domRoot.style.setProperty('--colorBgContainer', token.colorBgContainer);
    domRoot.style.setProperty('--colorBgLayout', token.colorBgLayout);
    domRoot.style.setProperty('--colorBorderSecondary', token.colorBorderSecondary);
    domRoot.style.setProperty('--colorText', token.colorText);
    domRoot.style.setProperty('--colorTextSecondary', token.colorTextSecondary);
    domRoot.style.setProperty('--colorTextTertiary', token.colorTextTertiary);
    domRoot.style.setProperty('--colorTextQuaternary', token.colorTextQuaternary);
    domRoot.style.setProperty('--colorSuccess', token.colorSuccess);
    domRoot.style.setProperty('--colorError', token.colorError);
    domRoot.style.setProperty('--colorWarning', token.colorWarning);
    domRoot.style.setProperty('--colorFill', token.colorFill);
    domRoot.style.setProperty('--colorFillSecondary', token.colorFillSecondary);
    domRoot.style.setProperty('--colorFillTertiary', token.colorFillTertiary);
    domRoot.style.setProperty('--colorFillQuaternary', token.colorFillQuaternary);
  }, [token]);

  return isUserLoggedIn() ? <Panel /> : <Auth />;
}

function AppWrapper() {
  let currentTheme =
    usePersistStore((state) => state.theme) ||
    (isDarkModePreferred() ? themes.dark : themes.default);

  if (!isUserLoggedIn()) {
    currentTheme = themes.default;
  }

  const themeConfig = {
    algorithm: currentTheme === themes.dark ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      borderRadius: 5,
      colorPrimary: primaryColor,
      colorBgLayout: currentTheme === themes.dark ? '#000000' : '#fafafa',
      colorBorderSecondary: currentTheme === themes.dark ? '#303030' : '#eaeaea',
    },
  };

  return (
    <ConfigProvider theme={themeConfig}>
      <App />
    </ConfigProvider>
  );
}

export default AppWrapper;
