import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  detectTimeZoneFromBrowser,
  detectTimeZoneFromDate,
  getDefaultUtcTimeZone,
  isValidTimeZone,
} from './utils/timeZoneFunctions';
import timeFormats from '@adamblok/onemon.io-common/enums/timeFormats';

function getPersistStoreName(userId = null) {
  return `appState${userId ? `-${userId}` : ''}`;
}

const usePersistStore = create(
  persist(
    (set, get) => ({
      setTheme: (theme) =>
        set({
          theme,
        }),
      incObjectsNum: (name) => {
        let num = get().getObjectsNum(name);
        get().setObjectsNum(name, num - 1);
      },
      decObjectsNum: (name) => {
        let num = get().getObjectsNum(name);
        get().setObjectsNum(name, num - 1);
      },
      setObjectsNum: (name, num) => {
        const objectsNum = get().objectsNum || {};
        objectsNum[name] = num;
        set({
          objectsNum: objectsNum,
        });
      },
      getObjectsNum: (name) => {
        const objectsNum = get().objectsNum;
        if (objectsNum && objectsNum[name] >= 0) {
          return objectsNum[name];
        }
        return 0;
      },
      getTimeZone: (api) => {
        if (get().timeZone) {
          return get().timeZone;
        }

        let timeZone = detectTimeZoneFromBrowser();
        if (timeZone) {
          get().setTimeZone(timeZone, api);
          return timeZone;
        }

        timeZone = detectTimeZoneFromDate();
        if (timeZone) {
          get().setTimeZone(timeZone, api);
          return timeZone;
        }

        return getDefaultUtcTimeZone();
      },
      setTimeZone: (timeZone, api) => {
        if (!isValidTimeZone(timeZone)) {
          return;
        }

        set({ timeZone });
        api.setTimeSettings(timeZone).then();
      },
      getTimeFormat: (api) => {
        if (get().timeFormat) {
          return get().timeFormat;
        }

        get().setTimeFormat(timeFormats.h24, api);
        return timeFormats.h24;
      },
      setTimeFormat: (timeFormat, api) => {
        set({ timeFormat });
        api.setTimeSettings(null, timeFormat).then();
      },
      getAuthToken: () => {
        const authToken = get().authToken;
        if (!authToken) {
          return null;
        }

        const authTokenParts = authToken.split('.');
        if (!authTokenParts || authTokenParts.length !== 3) {
          return null;
        }

        try {
          JSON.parse(atob(authTokenParts[0]));
          JSON.parse(atob(authTokenParts[1]));
        } catch (_) {
          return null;
        }

        return authToken;
      },
      setAuthToken: (authToken) =>
        usePersistStore.setState({
          authToken,
        }),
      getAuthTokenPayload: () => {
        try {
          const authToken = get().getAuthToken() || '';
          const authTokenParts = authToken.split('.');
          if (authTokenParts[1]) {
            return JSON.parse(atob(authTokenParts[1]));
          }
        } catch (_) {
          /* empty */
        }
        return null;
      },
      logout: () => {
        usePersistStore.setState({
          authToken: null,
        });
        window.location.href = '/';
      },
    }),
    {
      name: getPersistStoreName(window.localStorage.getItem('userId')),
    },
  ),
);

export { usePersistStore, getPersistStoreName };
