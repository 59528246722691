import config from '@adamblok/onemon.io-common/config/config';

export default {
  ...config,
  ...{
    landingUrl: import.meta.env.VITE_LANDING_URL,
    appUrl: import.meta.env.VITE_APP_URL,
    apiUrl: import.meta.env.VITE_API_URL,

    payPalOptions: {
      'client-id': import.meta.env.VITE_PAYPAL_CLIENT_ID,
      currency: 'USD',
      intent: 'subscription',
      vault: true,
    },

    googleClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,

    queryClientOptions: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 60 * 1000,
          retry: false,
        },
      },
    },
  },
};
